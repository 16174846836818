<script lang="ts" setup>
function scrollTo(className: string) {
  if (!document) return
  document.getElementsByClassName(className)[0].scrollIntoView({
    behavior: 'smooth'
  })
}
</script>

<template lang="pug">
.scroll-menu.flex.gap-20
  .logo
    nuxt-link(to="/")
      img(src="/images/logo-white.svg" alt="Abmeldung Digital" class="sm:h-8 md:h-10").h-6.w-auto

  .menu-items.items-center.text-slate-50.gap-12.text-lg.font-semibold.hidden(class="lg:flex")
    .menu-item(@click="scrollTo('vorteile-section')") Vorteile
    .menu-item(@click="scrollTo('anleitung-section')") Anleitung
    .menu-item.text-sky-200
      a(:href="`mailto:${useRuntimeConfig().public.email}`").flex.gap-3.items-center
        include ../public/images/icons/mail.svg
        span {{ useRuntimeConfig().public.email }}
</template>

<style lang="sass">
.scroll-menu
  .menu-item
    cursor: pointer

  svg path
    @apply stroke-sky-200
</style>
